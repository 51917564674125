html, body {
  width: 100%;
  overflow-x: hidden;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;
  position: relative;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

nav {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  width: 250px;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  background-color: #0005;
  z-index: 99;
}

nav .logo {
  margin-left: 20px;
  margin-top: 20px;
}

nav button {
  position: absolute;
  width: 80px;
  height: 80px;
  background: none;
  right: -80px;
  top: 0px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  opacity: .8;
  border: none;
  cursor: pointer;
}

nav button div {
  width: 26px;
  height: 2px;
  border-radius: 10px;
  background-color: white;
  margin: 4px;
}

nav button div:nth-child(2) {
  width: 12px;
}

nav ul {
  list-style: none;
  padding: 0;
  width: 100%;
}

nav ul li {
  padding: 0;
}

nav ul a {
  text-decoration: none;
  color: white;
  display: block;
  width: 100%;
  padding: 15px;
  padding-left: 20px;
  font-size: large;
  background-color: #fff0;
  transition: background-color .25s;
}

nav ul a:hover {
  background-color: #fff2;
}

@media (min-width: 992px) {
  nav button {
    width: 80px;
    height: 80px;
    right: -100px;
    top: 10px;
  }
  
  nav button div {
    width: 36px;
    height: 3px;
    border-radius: 10px;
    margin: 5px;
  }
  
  nav button div:nth-child(2) {
    width: 20px;
  }
}

/*************** HOME ***************/

.home {
  display: flex;
  background: black;
  padding: 0;
  flex-direction: column;
}

.home .main {
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  cursor: pointer;
}

.bg {
  position: absolute !important;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  transform: scale(1);
  transition: transform .5s;
  z-index: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.content {
  position: absolute !important;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.neon .n-logo {
  width: 260px;
}

.neon .n-features-main {
  width: 260px
}

.neon .n-features {
  width: 130px;
}

@media (min-width: 992px) {
  .home {
    flex-direction: row;
  }

  .neon .n-logo {
    width: 100%;
  }
  
  .neon .n-features-main {
    width: 100%;
  }
  
  .neon .n-features {
    width: auto;
  }
}

.home .main .bg {
  background: url(/public/images/puff-main-bg.png) no-repeat center;
  background-size: cover;
}

.home .main:hover .bg {
  transform: scale(1.05);
}

.home .side {
  flex: 1;
  display: grid;
  grid-template-rows: auto auto;
  grid-template-columns: auto auto;
}

.home .side a {
  display: block;
  cursor: pointer;
  overflow: hidden;
  position: relative;
}

.home .side a:hover .bg {
  transform: scale(1.1);
}

.home .side a:first-child .bg {
  background: url(/public/images/puff-ape-bg.jpg) no-repeat center;
  background-size: cover;
}

.home .side a:nth-child(2) .bg {
  background: url(/public/images/puff-party-bg.jpg) no-repeat center;
  background-size: cover;
}

.home .side a:nth-child(3) .bg {
  background: url(/public/images/puff-midi-bg.jpg) no-repeat center;
  background-size: cover;
}

.home .side a:nth-child(4) .bg {
  background: url(/public/images/puff-ape-bg.jpg) no-repeat center;
  background-size: cover;
}

/*************** SLIDER ***************/

.slider-container {
  display: flex;
  justify-content: center;
  position: relative;
  height: 370px;
}

.slider-container button {
  flex: 1;
  cursor: pointer;
  outline: none;
  border: none;
  background: none;
  opacity: .4;
  transition: opacity .25s;
}

.slider-container button img {
  width: 26px;
}

.slider-container button:hover {
  opacity: .7;
}

.slider {
  display: flex;
  position: relative;
  z-index: 2;
}

.slider .item {
  width: 270px;
  margin: 5px;
}

.slider .item img {
  width: 100%;
  height: auto;
  border-radius: 30px;
}

.slider .item h3 {
  font-size: 24px;
  margin: 0;
  margin-top: 8px;
  white-space: nowrap;
}

.slider .item p {
  font-size: 18px;
  margin: 0;
  margin-top: 0px;
  font-weight: 300;
  opacity: .8;
}

@media (min-width: 992px) {
  .slider-container {
    height: 600px;
  }

  .slider-container button img {
    width: auto;
  }

  .slider .item {
    width: 474px;
    margin: 5px;
  }
  
  .slider .item img {
    width: 100%;
    height: auto;
    border-radius: 40px;
  }
  
  .slider .item h3 {
    font-size: 32px;
    margin: 0;
    margin-top: 12px;
  }
  
  .slider .item p {
    font-size: 24px;
    margin: 0;
    margin-top: 6px;
    font-weight: 300;
    opacity: .8;
  }
}




section {
  padding: 30px 0;
  text-align: center;
  color: white;
}

section h2 {
  font-size: 30px;
  margin: 0;
  margin-bottom: 10px;
  white-space: nowrap;
}

.m-t-50 {
  margin-top: 50px;
}

section .desc {
  font-size: 16px;
  margin: 0;
  margin-bottom: 20px;
  font-weight: 300;
  padding: 0 20px;
}

.mini-img {
  border-radius: 30px;
  max-width: 1024px;
  width: 90%;
}


@media (min-width: 992px) {
  section {
    padding: 60px 0;
  }
  
  section h2 {
    font-size: 40px;
    margin: 0;
    margin-bottom: 10px;
  }
  
  .m-t-50 {
    margin-top: 50px;
  }
  
  section .desc {
    font-size: 20px;
    margin: 0;
    margin-bottom: 20px;
    font-weight: 300;
    padding: 0 200px;
  }

  .mini-img {
    border-radius: 40px;
  }
}



#neon, #footer {
  background: url(/public/images/main-bg-neon.jpg) no-repeat center;
  background-size: cover;
}

#ape {
  background: url(/public/images/main-bg-ape.jpg) no-repeat center;
  background-size: cover;
}

#party {
  background: url(/public/images/main-bg-party.jpg) no-repeat center;
  background-size: cover;
}

#maxi {
  background: url(/public/images/main-bg-maxi.jpg) no-repeat center;
  background-size: cover;
}

#midi {
  background: url(/public/images/main-bg-midi.jpg) no-repeat center;
  background-size: cover;
}

.map-container {
  width: 350px;
  height: 600px;
  margin: 0 auto;
  border-radius: 30px;
}

@media (min-width: 992px) {
  .map-container {
    width: 1000px;
    height: 900px;
    margin: 0 auto;
    border-radius: 40px;
  }
}